<template>
  <div>
    <el-dialog
      :title="getDialogTitle"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="illnessForm">
        <el-form-item :label="t('illness.name')" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="t('illness.guide')" prop="guide">
          <el-input
            v-model="ruleForm.guide"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, watch, getCurrentInstance, computed } from "vue";
export default {
  name: "illness_dialog",
  props: {
    row: Object,
    type: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const dialogVisible = ref(false);

    const ruleForm = reactive({
      name: "",
      guide: "",
    });

    const illnessForm = ref(null);

    const rules = reactive({
      name: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      guide: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      illnessForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      illnessForm.value.validate((valid) => {
        if (valid) {
          if (props.type === "create") {
            proxy.$emit("submit", ruleForm);
          } else {
            proxy.$emit("edit", ruleForm);
          }
        }
      });
    };

    const handleClose = () => {
      illnessForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const openDialog = () => {
      illnessForm.value?.resetFields();
      dialogVisible.value = true;
    };

    const closeDialog = () => {
      illnessForm.value?.resetFields();
      dialogVisible.value = false;
    };

    watch(props, () => {
      if (props.type !== "create") {
        ruleForm.id = props.row.id;
        ruleForm.name = props.row.name ? props.row.name : "";
        ruleForm.guide = props.row.guide ? props.row.guide : "";
      } else {
        ruleForm.name = "";
        ruleForm.guide = "";
      }
    });

    const getDialogTitle = computed(() => {
      switch (props.type) {
        case "create":
          return t("common.create");
        case "detail":
          return t("common.detail");
        default:
          return t("common.edit");
      }
    });

    return {
      t,
      dialogVisible,
      illnessForm,
      rules,
      ruleForm,
      openDialog,
      closeDialog,
      handleClose,
      resetForm,
      submitForm,
      getDialogTitle,
    };
  },
};
</script>

<style>
</style>
