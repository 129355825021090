import service from '@/utils/request'

// 分页获取Illness列表
export const search = (params) => {
    return service({
        url: '/illnesses',
        method: 'get',
        params
    })
}

// 获取指定Illness的详情
export const get = (params) => {
    return service({
        url: `/illness/${params.id}`,
        method: 'get'
    })
}

// 创建Illness
export const create = (data) => {
    return service({
        url: '/illness',
        method: 'post',
        data
    })
}

// 更新Illness信息
export const update = (data) => {
    return service({
        url: `/illness/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Illness
export const remove = (data) => {
    return service({
        url: `/illness/${data.id}`,
        method: 'delete',
        data
    })
}

// excel 导入Illness信息
export const syncIllnesses = (data) => {
    return service({
        url: '/sync/illnesses',
        method: 'post',
        data
    })
}
